import * as Sentry from '@sentry/browser';
import { useAtom, useAtomValue } from 'jotai';
import { Suspense, lazy, memo } from 'react';
import { type DialogKeys, dialogFamilyAtom, dialogKeysAtom } from '~/global-state/dialog';

const importMap = {
  ConfirmationDialog: () => import('../confirmation-dialog'),
  DeleteReportDialog: () => import('../delete-report-dialog'),
  GenerateReportDialog: () => import('../generate-report-dialog'),
  MeteringPointStateDialog: () => import('../metering-point-state-dialog'),
  MonthRangeDialog: () => import('../month-range-dialog'),
  MatchingResolutionDialog: () => import('../matching-resolution-dialog'),
};

export const DialogViewport = () => {
  const activeDialogKeys = useAtomValue(dialogKeysAtom);

  if (!activeDialogKeys.length) {
    return null;
  }

  return activeDialogKeys.map((id) => {
    return <AsyncDialogComponent key={id} id={id} />;
  });
};

const AsyncDialogComponent = memo(function AsyncDialogComponent({ id }: { id: DialogKeys }) {
  const [dialog] = useAtom(dialogFamilyAtom(id));

  const importer = importMap[id];

  if (!importer) {
    Sentry.captureMessage(`No import found for dialog component: ${id}`);
    return null;
  }

  const Component = lazy(() =>
    // biome-ignore lint/suspicious/noExplicitAny: This is a dynamic import and the type is unknown
    importer().then((module: { [key: string]: React.ComponentType<any> }) => ({
      default: module[id],
    })),
  );

  return (
    <Suspense fallback={null}>
      <Component
        {...{
          open: dialog.open,
          ...dialog.props,
        }}
      />
    </Suspense>
  );
});
